import { CheckCircle, NavigateNext, Sync, SyncProblem, Warning } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Info from "@mui/icons-material/Info";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { t } from "i18next";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AppStorage } from "~/src/contexts/StorageContext/StorageProvider";
import { Jaktdag, JaktdagResponse } from "../../api/types";
import { sumSettOgSkuttJaktdag } from "../../helpers/jaktdagHelper";
import { getFirstAndLastName } from "../../helpers/stringHelper";
import CounterChip from "../Chips/CounterChip";

type JaktdagCardProps = {
  data: JaktdagResponse;
  jegerNr?: number;
  syncStatus?: "pending" | "error";
  storage?: AppStorage;
};

type JaktdagCardSummary = {
  label?: "" | "innmark" | "utmark" | null;
  sett: number;
  skutt: number;
  jegere: number;
  timer: number;
};

export default function JaktdagCard({
  data,
  jegerNr,
  syncStatus,
  storage,
}: JaktdagCardProps): JSX.Element {
  let styles = "";
  const isRegByOther = jegerNr && data.Jegernummer !== jegerNr;

  if (isRegByOther) styles += " bg-md-creme-light";
  else styles += " bg-md-greengray";

  const sumSettOgSkutt = sumSettOgSkuttJaktdag(
    (storage && storage.setIndividerForJaktdag(data as Jaktdag)) || data,
  );

  const summaries: JaktdagCardSummary[] = [];

  if (data.Innmark) {
    const innmarkSummary: JaktdagCardSummary = {
      jegere: data.AntallJegereInnmark || 0,
      skutt: sumSettOgSkutt.sumSkuttInnmark,
      sett: sumSettOgSkutt.sumSettInnmark ?? 0,
      timer: data.AntallTimerJaktetInnmark || 0,
      label: "innmark",
    };

    summaries.push(innmarkSummary);
  }

  if ((data.Utmark && data.Art === "Hjort") || data.Art !== "Hjort") {
    const defaultSummary: JaktdagCardSummary = {
      jegere: data.AntallJegere || 0,
      skutt: sumSettOgSkutt.sumSkutt || 0,
      sett: sumSettOgSkutt.sumSett ?? 0,
      timer: data.AntallTimerJaktet || 0,
      label: data.Utmark ? "utmark" : null,
    };

    summaries.push(defaultSummary);
  }

  const [firstName, lastName] = getFirstAndLastName(data.RegistrertAv);

  const storageKey = `formDraft-${data.Id}`;
  const hasDraft = !!localStorage.getItem(storageKey);

  useEffect(() => {
    if (hasDraft && data.Godkjent) {
      localStorage.removeItem(storageKey);
    }
  }, []);

  return (
    <NavLink to={"/oversikt/jaktdagdetaljer/" + data.Id}>
      <div className={"p-3 rounded mb-2 active:bg-md-secondary " + styles}>
        <div className="flex gap-5 font-semibold mb-2">
          <div>{data.Dato && format(data.Dato || "", "d. MMM", { locale: nb })}</div>
          <div className="capitalize">{data.Art}</div>
          <div>
            {firstName.substring(0, 1)}. {lastName}
          </div>

          {data.Godkjent && (
            <CheckCircle
              className="text-md-primary ml-[-10px]"
              titleAccess={t("pages.oversikt.jaktdagenErGodkjent")}
            />
          )}
          {data.UtenforJakttid && (
            <Info
              className="text-md-warning-dark ml-[-10px]"
              titleAccess={t("pages.oversikt.jaktdagUtenforJakttid")}
            />
          )}
          {sumSettOgSkutt.isInconsistent && (
            <Warning
              className="text-md-error-red ml-[-10px]"
              titleAccess={t("pages.oversikt.inkonsistensSettSkutt")}
            />
          )}

          {syncStatus && (
            <div className="">
              {syncStatus === "pending" && (
                <Sync
                  className="text-md-primary text-2xl"
                  titleAccess="Individ har enda ikke blitt overført til hjorteviltregisteret"
                />
              )}
              {syncStatus === "error" && (
                <SyncProblem
                  className="text-md-error-red text-2xl"
                  titleAccess="Feil ved overføring"
                />
              )}
            </div>
          )}

          <div className="ml-auto">
            {hasDraft && !data.Godkjent && (
              <EditNoteIcon
                className="text-md-sea-green-dark ml-[-10px]"
                titleAccess={t("pages.oversikt.hasJaktdagDraftTitle")}
              />
            )}
            <NavigateNext />
          </div>
        </div>

        {summaries.map((summary, index) => (
          <div key={index}>
            {summary.label && <div className="text-xs mb-1 mt-3 uppercase">{summary.label}</div>}
            <div className="flex gap-2">
              <CounterChip
                label={t("components.jaktdagCard.seen")}
                count={summary.sett}
                fill={!isRegByOther}
              />
              <CounterChip
                label={t("components.jaktdagCard.shot")}
                count={summary.skutt}
                fill={!isRegByOther}
              />
              <CounterChip
                label={t("components.jaktdagCard.hunters")}
                count={summary.jegere}
                fill={!isRegByOther}
              />
              <CounterChip
                label={t("components.jaktdagCard.hours")}
                count={summary.timer}
                fill={!isRegByOther}
              />
            </div>
          </div>
        ))}
      </div>
    </NavLink>
  );
}
