import { harJaktPaArt } from "~/src/helpers/filters";
import { ArtType } from "~/src/helpers/types";
import { Favorittområde, IndividResponse, JaktdagResponse, Område } from "../api/types";
import { AppData } from "../contexts/AppDataContext/DataProvider";

export const getOmradeForIndivid = (
  individ: IndividResponse | undefined,
  appData: AppData,
): Område | null | undefined => {
  if (!individ) return null;

  if (individ.Jaktfelt) {
    return appData.jaktfelt.data?.data.find((jaktfelt) => jaktfelt.Id === individ.Jaktfelt);
  } else if (individ.Vald) {
    return appData.vald.data?.data.find((vald) => vald.Id === individ.Vald);
  } else if (individ.Villreinvald) {
    return appData.villreinvald.data?.data.find(
      (villreinvald) => villreinvald.Id === individ.Villreinvald,
    );
  }
};

export const getOmradeForJaktdag = (
  jaktdag: JaktdagResponse | null | undefined,
  appData: AppData,
): Område | null | undefined => {
  if (!jaktdag) return null;
  if (jaktdag.Jaktfelt) {
    return appData.jaktfelt.data?.data.find((jaktfelt) => jaktfelt.Id === jaktdag.Jaktfelt);
  }
};

export const getOmradeForOmradekode = (
  art: ArtType,
  omradekode: string,
  appData: AppData,
): Område | null | undefined => {
  if (omradekode.includes("J")) {
    return appData.jaktfelt.data?.data.find((jaktfelt) => jaktfelt.Id === omradekode);
  } else if (omradekode.includes("V")) {
    if (art === ArtType.Villrein) {
      return appData.villreinvald.data?.data.find((villreinvald) => villreinvald.Id === omradekode);
    }
    //Rådyr
    return appData.vald.data?.data.find((vald) => vald.Id === omradekode);
  }
};

export const getOmradeForSingleFavoritt = (
  appData: AppData,
  art: ArtType,
): Favorittområde | undefined => {
  if (appData.jaktområder.data?.length === 0) {
    return undefined;
  }
  const omrader = appData.jaktområder.data?.filter((omrade) => harJaktPaArt(art, omrade)) || [];
  if (omrader.length !== 1) {
    return undefined;
  }
  return omrader.at(0);
};

export const getOmradeKeyForArt = (art: ArtType) => {
  switch (art) {
    case ArtType.Elg:
      return "Jaktfelt";
    case ArtType.Hjort:
      return "Jaktfelt";
    case ArtType.Rådyr:
      return "Vald";
    case ArtType.Villrein:
      return "Villreinvald";
    default:
      throw Error(`Omrade key for ${art} not implemented.`);
  }
};
