import { Info, SystemUpdate } from "@mui/icons-material";
import Article from "@mui/icons-material/Article";
import Call from "@mui/icons-material/Call";
import Help from "@mui/icons-material/Help";
import LanguageIcon from "@mui/icons-material/Language";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Text from "~/src/components/Text/Text";
import { retrieve, store } from "~/src/contexts/StorageContext/helpers";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import getAppInfo, { AppInfo } from "~/src/helpers/getAppInfo";
import { appConfig } from "../../../appConfig";
import ExternalLink from "../../../components/Links/ExternalLink";
import InternalLink from "../../../components/Links/InternalLink";
import { useDialog } from "../../../hooks/useDialog";

type VersionCheck = {
  appInfo: AppInfo;
  notifyIfNoUpdate: boolean;
};

export default function Lenker(): JSX.Element {
  const ui = useContext(UIContext);
  const appInfo = getAppInfo();
  const { t, i18n } = useTranslation();
  const dialog = useDialog({
    title: t("pages.minSide.sprak.dialogTittel"),
  });

  useEffect(() => {
    const storedVersionCheck = retrieve<VersionCheck>("versionCheck");
    if (
      storedVersionCheck === undefined ||
      storedVersionCheck.appInfo.version !== appInfo.version
    ) {
      ui.showSnackbar({ message: t("app.updated") });
    } else if (storedVersionCheck.notifyIfNoUpdate) {
      ui.showSnackbar({ message: t("app.noUpdate") });
    }
    store("versionCheck", { appInfo: appInfo, notifyIfNoUpdate: false });
  }, []);

  const changeLanguageHandler = (lng: string) => {
    i18n.changeLanguage(lng);
    const language =
      lng === "nb" ? t("pages.minSide.sprak.bokmal") : t("pages.minSide.sprak.nynorsk");
    dialog.show({
      message: t("pages.minSide.sprak.dialogTekst", { language }),
    });
  };

  const checkForNewVersionHandler = () => {
    store("versionCheck", { appInfo: appInfo, notifyIfNoUpdate: true });
    window.location.reload();
  };

  return (
    <div>
      <ul className="flex flex-col flex-nowrap break-all list-none m-8 gap-8">
        {i18n.language === "nb" ? (
          <li onClick={() => changeLanguageHandler("nn")}>
            <InternalLink
              path="#"
              text={`${t("pages.minSide.sprak.byttTil")} ${t(
                "pages.minSide.sprak.nynorsk",
              ).toLowerCase()}`}
              icon={<LanguageIcon />}
            />
          </li>
        ) : (
          <li onClick={() => changeLanguageHandler("nb")}>
            <InternalLink
              path="#"
              text={`${t("pages.minSide.sprak.byttTil")} ${t(
                "pages.minSide.sprak.bokmal",
              ).toLowerCase()}`}
              icon={<LanguageIcon />}
            />
          </li>
        )}
        <li>
          <InternalLink
            path="/minside/aldersbestemmelse"
            text={t("pages.minSide.lenker.aldersbestemmelse")}
            icon={<Article />}
          />
        </li>
        <li>
          <ExternalLink
            url="https://www.hjorteviltregisteret.no/OfteStilteSp%C3%B8rsm%C3%A5l#kategori-heading-5"
            text={t("pages.minSide.lenker.dataBruk")}
          />
        </li>
      </ul>
      {/* <div className="flex-wrap mx-6 pt-2 break-all">
        <Typography variant="h6" color="gray">
          {t("pages.minSide.lenker.skjema")}
        </Typography>
      </div>
      <ul className="flex flex-col flex-nowrap break-all list-none m-4 mx-8">
        <li>
          <ExternalLink
            url="https://www.hjorteviltregisteret.no/VillsvinGodtgj%C3%B8relser/Opprett"
            text={t("pages.minSide.lenker.skjemaVillsvin")}
            icon={<Article />}
          />
        </li>
      </ul> */}
      <div className="flex-wrap mx-6 pt-2 break-all">
        <Text variant="h6" color="gray">
          {t("pages.minSide.lenker.hjelpOgKontakt")}
        </Text>
      </div>
      <ul className="flex flex-col flex-nowrap gap-8 m-4 mx-8">
        <li>
          <ExternalLink
            url="https://www.hjorteviltregisteret.no/OfteStilteSp%C3%B8rsm%C3%A5l#kategori-Sett%20og%20skutt%20app"
            text={t("pages.minSide.lenker.oss")}
            icon={<Help />}
          />
        </li>
        <li>
          <InternalLink
            path="/minside/kontakt"
            text={t("pages.minSide.lenker.kontaktinformasjon")}
            icon={<Call />}
          />
        </li>
        <li>
          <InternalLink path="/minside/om" text={t("pages.minSide.lenker.om")} icon={<Info />} />
        </li>
        <li onClick={() => checkForNewVersionHandler()}>
          <InternalLink path="#" text={t("pages.minSide.update.get")} icon={<SystemUpdate />} />
        </li>
        <li>
          <ExternalLink
            url="https://uustatus.no/nb/erklaringer/publisert/a5ba02f3-e657-4a02-a849-88029e797aef"
            text={t("pages.minSide.lenker.tilgjengelighet")}
          />
        </li>
        <li>
          <ExternalLink
            url={appConfig.urls.personvernerklaering}
            text={t("pages.minSide.lenker.personvern")}
          />
        </li>
      </ul>
      {dialog.element}
    </div>
  );
}
