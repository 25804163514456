import { IndividResponse } from "../../api/types";

export function isMissingOvervakData(skuttDyr: IndividResponse) {
  return skuttDyr.SkuttIOvervåkområde && (!skuttDyr.Merkelapp?.trim() || !skuttDyr.SlaktevektKg);
}

export function getReadableEnumLowercase(enumProp: string | null | undefined) {
  if (!enumProp) {
    return "";
  }
  return enumProp
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
}
