import { Context, useContext } from "react";

export type FormDataContext<T> = {
  formData: T;
  setFormData: (data: T) => void;
  errors: Partial<Record<keyof T, string>>;
  setErrors: (errors: Partial<Record<keyof T, string>>) => void;
};

export function useFormDataContext<T>(formDataContext: Context<FormDataContext<T> | undefined>) {
  const context = useContext(formDataContext);

  if (!context) {
    throw Error(`useFormDataContext must be used within ${formDataContext}`);
  }
  return context;
}
