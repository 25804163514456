type InputLabelProps = {
  for?: string;
  label: string;
  onClick: () => void;
  isError?: boolean;
  isFocused?: boolean;
  hasValue?: boolean;
};

const InputLabel = (props: InputLabelProps) => {
  const placement = props.isFocused || props.hasValue ? "top" : "middle";
  let textColor;
  switch (true) {
    case props.isError:
      textColor = "text-md-error-red";
      break;
    default:
      textColor = "text-md-seagray";
      break;
  }
  return (
    <label
      htmlFor={props.for}
      className={`absolute left-4 top-3.5 transition-all duration-150 ease-in-out font-normal ${textColor} ${
        placement === "top" ? "text-xs transform -translate-y-[14px]" : "text-sm "
      }
        ${placement === "middle" ? "cursor-text" : ""}`}
      onClick={props.onClick}
    >
      {props.label}
    </label>
  );
};

export default InputLabel;
