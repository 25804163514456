import { t } from "i18next";
import { NOT_AUTHENTICATED_STATUS_CODES } from "~/src/api/jaktdataApi";
import { ApiError, Områdekode } from "~/src/api/types";
import { jaktdataApi } from "~/src/appGlobals";
import { isDeletedOnServer, syncStatusForError } from "~/src/contexts/AppDataContext/syncHelpers";
import { AppStorage, StorageItem } from "~/src/contexts/StorageContext/StorageProvider";
import { UIContextData } from "~/src/contexts/UIContext/UIProvider";

export const syncJaktomrader = async (storage: AppStorage, ui: UIContextData) => {
  const pendingJaktomrader = storage.getAll<Områdekode>("PendingJaktomrade");
  if (pendingJaktomrader.length === 0) return;

  const omradekodeForOmrade = (omrade: StorageItem<Områdekode>) => {
    return { Områdekode: omrade.data.Områdekode, Områdetype: omrade.data.Områdetype };
  };

  const toCreate = pendingJaktomrader.filter((jaktomrade) => jaktomrade.operation === "create");
  const toDelete = pendingJaktomrader.filter((jaktomrade) => jaktomrade.operation === "delete");

  let omraderSynced = 0;
  let omraderNotSynced = 0;

  let createRequests: Promise<void>[] = [];
  let deleteRequests: Promise<void>[] = [];
  const syncConfirm = (omrade: StorageItem<Områdekode>) => {
    omraderSynced += 1;
    storage.delete(omrade.objectKey, "PendingJaktomrade");
  };
  const syncReject = (omrade: StorageItem<Områdekode>, e: ApiError) => {
    omraderNotSynced += 1;
    if (!NOT_AUTHENTICATED_STATUS_CODES.includes(e.statusCode)) {
      const accumulatedUniqueErrors = Array.from(
        new Set((omrade.errors || []).concat([e.message])),
      );
      storage.add({
        ...omrade,
        syncStatus: syncStatusForError(e),
        errors: accumulatedUniqueErrors,
      });
    }
  };

  for (const omrade of toCreate) {
    createRequests.push(
      jaktdataApi
        .postJaktområde(omradekodeForOmrade(omrade))
        .then(() => syncConfirm(omrade))
        .catch((e: ApiError) => syncReject(omrade, e)),
    );
  }

  for (const omrade of toDelete) {
    deleteRequests.push(
      jaktdataApi
        .deleteJaktområde(omradekodeForOmrade(omrade))
        .then(() => syncConfirm(omrade))
        .catch((e: ApiError) => {
          if (isDeletedOnServer(e)) {
            syncConfirm(omrade);
          } else {
            syncReject(omrade, e);
          }
        }),
    );
  }

  return Promise.all(createRequests)
    .then(() => Promise.all(deleteRequests))
    .then(() => {
      if (omraderSynced > 0) {
        ui.showSnackbar({
          message: t("sync.success", {
            count: omraderSynced,
            type: t("sync.type.favorittområder"),
          }),
        });
      }
      if (omraderNotSynced > 0) {
        ui.showSnackbar({
          message: t("sync.failure", {
            count: omraderNotSynced,
            type: t("sync.type.favorittområder"),
            details: "",
          }),
        });
      }
      return { omraderSynced, omraderNotSynced };
    });
};

export type SyncJaktomraderResult = {
  omraderSynced: number;
  omraderNotSynced: number;
};

export const SyncJaktomraderResultDefaults: SyncJaktomraderResult = {
  omraderSynced: 0,
  omraderNotSynced: 0,
};
