import { Link } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Område } from "../../api/types";
import AlertDefault, { AlertVariant } from "../../components/Alerts/AlertDefault";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";
import ShowMoreToggle from "../../components/ShowMoreToggle";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import { getOmradeForIndivid } from "../../helpers/omradeHelper";
import { toStringWithFullMonth } from "../../helpers/time";
import { AlderType, ArtType, KjønnType } from "../../helpers/types";
import { useStateful } from "../../hooks/useStateful";
import { getSkuttDyrQuery } from "../../react-query/queries";

// TODO This is just a temp page since villsvin endpoints haven't been made yet! Implement page according to spec when endpoint is ready.

export default function SkuttDyrDetaljerVillsvin(): JSX.Element {
  const { individId } = useParams();
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const omrade = useStateful<Område | null | undefined>(null);

  const skuttDyr = useQuery({
    queryKey: ["individId-" + individId, { individId: individId }],
    queryFn: getSkuttDyrQuery,
    staleTime: 0,
  }).data;

  useEffect(() => {
    omrade.set(getOmradeForIndivid(skuttDyr, appData));
  }, [skuttDyr]);

  const storageKey = `formDraft-${skuttDyr?.Id}`;
  const hasDraft = !!localStorage.getItem(storageKey);
  useEffect(() => {
    if (hasDraft && skuttDyr?.Godkjent) {
      localStorage.removeItem(storageKey);
    }
  }, []);

  const removeDraft = () => {
    localStorage.removeItem(storageKey);
    navigate(-1);
  };

  const Field = ({
    label,
    value,
    show = true,
  }: {
    label: string;
    value: JSX.Element | string | null | undefined;
    show?: boolean;
  }): JSX.Element => {
    return (
      <>
        {show && (
          <div className="flex gap-2">
            <div className="text-md-gray text-nowrap">{label}:</div>
            <div>{value}</div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="h-max">
      {/* Just to distinguish, remove when implementing spec */}
      <h3>Villsvin</h3>

      {skuttDyr && (
        <>
          <div className="px-4 py-2 bg-md-secondary text-md-gray">
            {omrade.value?.Navn} <span className="ml-2 text-xs">{omrade.value?.Id}</span>
          </div>
          {hasDraft && !skuttDyr?.Godkjent && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t("pages.oversikt.hasJaktdagDraftDescription") + " "}
              <Link onClick={removeDraft}>{t("draft.deleteDraft")}</Link>
            </AlertDefault>
          )}
          <div className="p-4 flex flex-col gap-2">
            <Field
              show={skuttDyr.Art === ArtType.Hjort}
              label={t("pages.oversikt.skuttDyrDetaljer.terreng")}
              value={skuttDyr.Terreng}
            />

            <Field
              label={t("pages.oversikt.skuttDyrDetaljer.skutt")}
              value={toStringWithFullMonth(skuttDyr.Dato)}
            />

            <Field
              label={t("pages.oversikt.skuttDyrDetaljer.slaktevekt")}
              value={
                skuttDyr.SlaktevektKg
                  ? skuttDyr.SlaktevektKg + " kg " + skuttDyr.MålemetodeSlaktevekt
                  : "-"
              }
            />

            <Field
              show={skuttDyr.Art !== ArtType.Villrein}
              label={t("pages.oversikt.skuttDyrDetaljer.merkelappnr")}
              value={skuttDyr.Merkelapp ? skuttDyr.Merkelapp : "-"}
            />

            <Field
              show={skuttDyr.Art === ArtType.Villrein}
              label={t("pages.oversikt.skuttDyrDetaljer.kontrollkort")}
              value={skuttDyr.Kontrollkort ? skuttDyr.Kontrollkort : "-"}
            />

            <Field
              label={t("pages.oversikt.skuttDyrDetaljer.cwdProvesvar")}
              value={skuttDyr.CWDPrøvesvar ? skuttDyr.CWDPrøvesvar : "-"}
            />

            <Field
              show={skuttDyr.Art === ArtType.Rådyr && skuttDyr.MelkIJuret !== null}
              label={t("pages.oversikt.skuttDyrDetaljer.melkIJuret")}
              value={skuttDyr.MelkIJuret ? "Ja" : "Nei"}
            />

            <Field
              show={
                skuttDyr.Alder === AlderType.Voksen &&
                (skuttDyr.Kjønn === KjønnType.Hann ||
                  (skuttDyr.Kjønn === KjønnType.Hunn && skuttDyr.Art === ArtType.Villrein))
              }
              label={t("pages.oversikt.skuttDyrDetaljer.gevirtakker")}
              value={(skuttDyr.GevirtakkerVenstre ?? "") + "-" + (skuttDyr.GevirtakkerHøyre ?? "")}
            />

            <Field
              show={!!skuttDyr.Fellingssted}
              label={t("pages.oversikt.skuttDyrDetaljer.fellingssted")}
              value={skuttDyr.Fellingssted}
            />

            <Field
              show={!!skuttDyr.SistEndretAv}
              label={t("pages.oversikt.skuttDyrDetaljer.sistEndretAv")}
              value={skuttDyr.SistEndretAv}
            />

            <Field
              label={t("pages.oversikt.skuttDyrDetaljer.registrertAv")}
              value={skuttDyr.RegistrertAv ? skuttDyr.RegistrertAv : "-"}
            />

            <Field
              show={!!skuttDyr.Merknad}
              label={t("pages.oversikt.skuttDyrDetaljer.merknad")}
              value={<ShowMoreToggle limit={50}>{skuttDyr.Merknad}</ShowMoreToggle>}
            />
          </div>

          {!skuttDyr.Godkjent && (
            <div className="p-4 text-right space-x-3 sticky bottom-0">
              <ButtonDefault id="sdf" label="Slett" variant={ButtonVariant.SecondaryWhite} />
              <ButtonDefault
                id="sdf"
                label="Endre"
                variant={ButtonVariant.Primary}
                onClick={() => navigate("/registrering/fellingsinfo/" + skuttDyr.Id)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
