import { t } from "i18next";
import { NOT_AUTHENTICATED_STATUS_CODES } from "~/src/api/jaktdataApi";
import { ApiError, Individ } from "~/src/api/types";
import { jaktdataApi } from "~/src/appGlobals";
import { isDeletedOnServer, syncStatusForError } from "~/src/contexts/AppDataContext/syncHelpers";
import { postIndividQuery, putIndividQuery } from "~/src/react-query/queries";
import { AppStorage, StorageItem } from "../StorageContext/StorageProvider";
import { UIContextData } from "../UIContext/UIProvider";

export const syncIndivider = async (storage: AppStorage, ui: UIContextData) => {
  const pendingIndivider = storage.getAll<Individ>("Individ");
  if (pendingIndivider.length === 0) return;

  const toCreate = pendingIndivider.filter((individ) => individ.operation === "create");
  const toUpdate = pendingIndivider.filter((individ) => individ.operation === "update");
  const toDelete = pendingIndivider.filter((individ) => individ.operation === "delete");

  let individerSynced = 0;
  let individerNotSynced = 0;

  let createRequests: Promise<void>[] = [];
  let updateRequests: Promise<void>[] = [];
  let deleteRequests: Promise<void>[] = [];

  const syncConfirm = (individ: StorageItem<Individ>) => {
    individerSynced += 1;
    storage.delete({ id: individ.data.Id }, "Individ");
  };
  const syncReject = (individ: StorageItem<Individ>, e: ApiError) => {
    individerNotSynced += 1;
    if (!NOT_AUTHENTICATED_STATUS_CODES.includes(e.statusCode)) {
      storage.add({
        ...individ,
        syncStatus: syncStatusForError(e),
        errors: [e.message],
      });
    }
  };

  for (const individ of toCreate) {
    createRequests.push(
      postIndividQuery(individ.data)
        .then(() => syncConfirm(individ))
        .catch((e: ApiError) => syncReject(individ, e)),
    );
  }

  for (const individ of toUpdate) {
    updateRequests.push(
      putIndividQuery(individ.data)
        .then(() => syncConfirm(individ))
        .catch((e: ApiError) => {
          if (isDeletedOnServer(e)) {
            syncConfirm(individ);
          } else {
            syncReject(individ, e);
          }
        }),
    );
  }

  for (const individ of toDelete) {
    deleteRequests.push(
      jaktdataApi
        .deleteIndivid(individ.data.Id)
        .then(() => syncConfirm(individ))
        .catch((e: ApiError) => {
          if (isDeletedOnServer(e)) {
            syncConfirm(individ);
          } else {
            syncReject(individ, e);
          }
        }),
    );
  }

  return Promise.all(createRequests)
    .then(() => Promise.all(updateRequests))
    .then(() => Promise.all(deleteRequests))
    .then(() => {
      if (individerSynced > 0) {
        ui.showSnackbar({
          message: t("sync.success", {
            count: individerSynced,
            type: t("sync.type.individ"),
          }),
        });
      }
      if (individerNotSynced > 0) {
        ui.showSnackbar({
          message: t("sync.failure", {
            count: individerNotSynced,
            type: t("sync.type.individ"),
            details: t("sync.syncErrorDetailsWhereIndivid"),
          }),
        });
      }
      return {
        individerSynced,
        individerNotSynced,
        totalItemsHandled: individerSynced + individerNotSynced,
      };
    });
};

export type SyncIndividerResult = {
  individerSynced: number;
  individerNotSynced: number;
  totalItemsHandled: number;
};

export const SyncIndividerResultDefaults: SyncIndividerResult = {
  individerSynced: 0,
  individerNotSynced: 0,
  totalItemsHandled: 0,
};
