import { PropsWithChildren } from "react";
import { useStateful } from "../hooks/useStateful";

type ShowMoreToggleProps = PropsWithChildren & {
  children?: string | null;
  limit: number;
};
export default function ShowMoreToggle({ children, limit }: ShowMoreToggleProps): JSX.Element {
  const isExpanded = useStateful(false);
  const isOverLimit = children && children.length > limit;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    isExpanded.set(!isExpanded.value);
  };

  return (
    <>
      {!isOverLimit && children}
      {isOverLimit && (
        <>
          {isExpanded.value ? children : children.substring(0, limit) + "..."}
          <a href="#" onClick={handleClick} className="text-md-primary text-nowrap ml-2">
            Vis {isExpanded.value ? "mindre" : "mer"}
          </a>
        </>
      )}
    </>
  );
}
