import { Warning } from "@mui/icons-material";
import { Link } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StorageContext, StorageItem } from "~/src/contexts/StorageContext/StorageProvider";
import { retrieve } from "~/src/contexts/StorageContext/helpers";
import { sumSettOgSkuttJaktdag, sumSettOgSkuttJaktdagDefault } from "~/src/helpers/jaktdagHelper";
import { FormDataWrapper } from "~/src/hooks/useFormData";
import { ApiError, Individ, Jaktdag, JaktdagResponse, Område } from "../../api/types";
import { jaktdataApi } from "../../appGlobals";
import { AccordionDefault } from "../../components/Accordion/AccordionDefault";
import AlertDefault, { AlertVariant } from "../../components/Alerts/AlertDefault";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";
import PageTitle from "../../components/PageTitle";
import SkuttDyrCard from "../../components/SkuttDyrCard/SkuttDyrCard";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import UIContext from "../../contexts/UIContext/UIProvider";
import { getOmradeForJaktdag } from "../../helpers/omradeHelper";
import { toStringWithFullMonth } from "../../helpers/time";
import { ArtType, SumSettOgSkuttJaktdag } from "../../helpers/types";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useStateful } from "../../hooks/useStateful";
import { getJaktdagQuery } from "../../react-query/queries";
import { Terreng } from "../Registrering/types";
import { Counter, CounterWithLabel } from "./Components/Counter";
import OmradeBanner from "./Components/OmradeBanner";

export default function JaktdagDetaljer() {
  const tBase = "pages.oversikt.jaktdagDetaljer.";
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const queryClient = useQueryClient();
  const { jaktdagId } = useParams();
  const omrade = useStateful<Område | null | undefined>(null);
  const canEdit = useStateful(false);
  const uiContext = useContext(UIContext);
  const storage = useContext(StorageContext);
  const { showSnackbar } = useContext(UIContext);
  const isDeleting = useStateful<boolean>(false);
  const navigateToOversiktJaktdager = () => {
    navigate("/oversikt", { state: { activeTab: 0 } });
  };
  const [sumSettOgSkutt, setSumSettOgSkutt] = useState<SumSettOgSkuttJaktdag>(
    sumSettOgSkuttJaktdagDefault(),
  );
  const [storedJaktdag, setStoredJaktdag] = useState<StorageItem<Jaktdag> | null | undefined>(null);
  const [jaktdag, setJaktdag] = useState<Jaktdag | null | undefined>(null);

  const storageKey = `formState-/registrering/jaktdag/${jaktdagId}`;
  const formDraftWrapped = retrieve<FormDataWrapper<Jaktdag>>(storageKey);
  const hasDraft = formDraftWrapped?.isDraft;
  const formDraft = formDraftWrapped?.data;

  useEffect(() => {
    if (hasDraft && jaktdag?.Godkjent) {
      localStorage.removeItem(storageKey);
    }
  }, []);

  const removeDraft = () => {
    localStorage.removeItem(storageKey);
    navigateToOversiktJaktdager();
    showSnackbar({
      title: t("pages.registrering.dialogs.draftRemoved.title"),
      message: t("pages.registrering.dialogs.draftRemoved.message"),
    });
  };

  const jaktdagQuery = useQuery({
    queryKey: ["jaktdagId-" + jaktdagId, { jaktdagId: jaktdagId }],
    queryFn: getJaktdagQuery,
    staleTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (appData.didTrySyncWithItems.value) {
      jaktdagQuery.refetch();
    }
  }, [appData.didTrySyncWithItems.value]);

  useEffect(() => {
    if (jaktdag) {
      omrade.set(getOmradeForJaktdag(jaktdag, appData));
      canEdit.set(!jaktdag.Godkjent);
      setSumSettOgSkutt(sumSettOgSkuttJaktdag(jaktdag));
    }
  }, [jaktdag]);

  useEffect(() => {
    const storedJaktdag = storage.get<Jaktdag>({ id: jaktdagId }, "Jaktdag");
    let _jaktdag: Jaktdag | undefined = undefined;
    let _storedJaktdag: StorageItem<Jaktdag> | undefined = undefined;

    if (
      storedJaktdag !== undefined &&
      (["update", "create"].includes(storedJaktdag.operation || "other") ||
        storedJaktdag.errors?.length ||
        0 > 0)
    ) {
      setStoredJaktdag(storedJaktdag);
      _storedJaktdag = storedJaktdag;
    } else if (
      appData.isOnline &&
      jaktdagQuery.data !== undefined &&
      typeof jaktdagQuery.data !== "string" &&
      "Jaktfelt" in jaktdagQuery.data
    ) {
      _jaktdag = jaktdagQuery.data as Jaktdag;
    }

    const jaktdagToSet = _storedJaktdag?.data || _jaktdag;
    if (_storedJaktdag && _jaktdag) {
      _storedJaktdag.data.Individer = _jaktdag.Individer;
    }
    if (jaktdagToSet) {
      setJaktdag(storage.setIndividerForJaktdag(jaktdagToSet));
    }
  }, [appData.isOnline, jaktdagQuery.data]);

  const handleDeleteClick = () => {
    deleteDialog.show();
  };
  const handleConfirmDelete = () => {
    if (!jaktdag?.Id) return;
    jaktdataApi
      .deleteJaktdag(jaktdag?.Id)
      .then(() => {
        isDeleting.set(true);
        const queryKey = {
          queryKey: [
            "jaktdager",
            {
              jaktomradeId: omrade.value?.Id || "",
              sesong: new Date().getFullYear(),
              artFilter: [jaktdag.Art],
            },
          ],
        };
        queryClient.invalidateQueries(queryKey).then(() => {
          console.log("invalidated jaktdager");
          queryClient.refetchQueries(queryKey).then(() => {
            console.log("refetched jaktdager");
            uiContext.showSnackbar({ message: t(tBase + "jaktdagDeletedMessage") });
            isDeleting.set(false);
            navigateToOversiktJaktdager();
          });
        });
      })
      .catch((e: ApiError) => {
        if (e.axiosError?.code === "ERR_NETWORK") {
          // remove any pending individer
          const storedIndivider = storage
            .getAll<Individ>("Individ")
            .filter((individ) => individ.data.JaktdagId === jaktdag.Id);
          storedIndivider.forEach((individ) => storage.delete({ id: individ.data.Id }, "Individ"));

          const storedJaktdag = storage.get<JaktdagResponse>({ id: jaktdag.Id }, "Jaktdag");
          if (storedJaktdag?.operation === "create") {
            storage.delete({ id: jaktdag.Id }, "Jaktdag");

            navigateToOversiktJaktdager();
            uiContext.showSnackbar({
              message: t("pages.oversikt.jaktdagDetaljer.jaktdagDeletedMessage"),
            });
            return;
          }

          storage.add({
            objectType: "Jaktdag",
            objectKey: { id: jaktdag.Id },
            data: jaktdag,
            isPending: true,
            operation: "delete",
            lastUpdated: new Date(),
          });

          uiContext.showSnackbar({
            message: t("sync.noNetworkWillSyncLater"),
          });

          navigateToOversiktJaktdager();
          return;
        }

        uiContext.showSnackbar({ message: e.message });
        navigateToOversiktJaktdager();
      });
  };

  const deleteDialog = useConfirmationDialog({
    title: t(tBase + "deleteDialogTitle"),
    message: t(tBase + "deleteDialogMessage"),
    onConfirm: handleConfirmDelete,
    confirmButtonText: t("actions.delete"),
  });

  const tCounterLabels = "pages.registrerArt." + jaktdag?.Art?.toLowerCase() + ".sett";

  if (!jaktdag) {
    return <div className="m-4">{t("errors.notFound", { type: "Jaktdag" })}</div>;
  }

  const removeStoredJaktdag = () => {
    if (!storedJaktdag) {
      return;
    }
    storage.delete(storedJaktdag.objectKey, "Jaktdag");
    window.location.reload();
  };

  if (isDeleting.value) {
    return <CircularProgress />;
  }

  return (
    <>
      {jaktdag && (
        <>
          <PageTitle width={250}>
            {t(tBase + "jaktdagFor")} {jaktdag.Art?.toLowerCase()}{" "}
            {toStringWithFullMonth(formDraft?.Dato || jaktdag.Dato)}
          </PageTitle>

          <OmradeBanner omrade={omrade} />

          {!canEdit.value && (
            <AlertDefault variant={AlertVariant.Info} className="mx-4 mt-4">
              {t(tBase + "kanIkkeEndres")}
            </AlertDefault>
          )}

          {jaktdag.UtenforJakttid && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t("pages.oversikt.jaktdagUtenforJakttid")}
            </AlertDefault>
          )}
          {hasDraft && !jaktdag?.Godkjent && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t("pages.oversikt.hasJaktdagDraftDescription") + " "}
              <Link onClick={removeDraft}>{t("draft.deleteDraft")}</Link>
            </AlertDefault>
          )}

          {sumSettOgSkutt.isInconsistent && (
            <AlertDefault variant={AlertVariant.Error} showIcon className="mx-4 mt-4">
              {t("pages.oversikt.inkonsistensSettSkutt")}
            </AlertDefault>
          )}

          {storedJaktdag?.isPending && (
            <AlertDefault variant={AlertVariant.Sync} showIcon className="mx-4 mt-4">
              {t("sync.pending")}
            </AlertDefault>
          )}

          {storedJaktdag?.syncStatus === "error" && (
            <AlertDefault variant={AlertVariant.SyncError} showIcon className="mx-4 mt-4">
              <div>{t("sync.syncError")}</div>

              {storedJaktdag.errors && storedJaktdag.errors.length > 0 && (
                <ul className="list-disc pl-5">
                  {storedJaktdag?.errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              )}
              {appData.isOnline && (
                <Link onClick={removeStoredJaktdag}>{t("actions.discardAndRestore")}</Link>
              )}
            </AlertDefault>
          )}

          <div className="m-4">
            <div className="flex gap-6 mx-2">
              <div className="flex items-center">
                {t("components.jaktdagCard.hunters")}{" "}
                <Counter count={(jaktdag.AntallJegere || 0) + (jaktdag.AntallJegereInnmark || 0)} />
              </div>
              <div className="flex items-center">
                {t(tBase + "timerJaktet")}{" "}
                <Counter
                  count={(jaktdag.AntallTimerJaktet || 0) + (jaktdag.AntallTimerJaktetInnmark || 0)}
                />
              </div>
            </div>

            {/* Innmark - kun hjort */}
            {jaktdag.Innmark && (
              <>
                <AccordionDefault
                  headerContent={
                    <div className="flex justify-between">
                      <div>
                        {t("pages.registrerArt.sett")}{" "}
                        {t("pages.fellingsinfo.innmark").toLowerCase()}
                        {sumSettOgSkutt.errors.innmark && (
                          <Warning className="text-md-error-red ml-2" />
                        )}
                      </div>
                      <div className="text-neutral-500 text-base font-normal">
                        {t("pages.oversikt.jaktdagDetaljer.sum")} {sumSettOgSkutt.sumSettInnmark}
                      </div>
                    </div>
                  }
                >
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettBukkInnmark`)}
                    count={jaktdag.SettBukkInnmark}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettSpissbukkInnmark`)}
                    count={jaktdag.SettSpissbukkInnmark}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettKolleInnmark`)}
                    count={jaktdag.SettKolleInnmark}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettKalvInnmark`)}
                    count={jaktdag.SettKalvInnmark}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettUkjenteInnmark`)}
                    count={jaktdag.SettUkjenteInnmark}
                  />
                </AccordionDefault>

                <AccordionDefault
                  defaultOpen={false}
                  headerContent={
                    <div className="flex justify-between">
                      <div>
                        {t("pages.registrerArt.skutt")}{" "}
                        {t("pages.fellingsinfo.innmark").toLowerCase()}
                      </div>
                      <div className="text-neutral-500 text-base font-normal">
                        {t("pages.oversikt.jaktdagDetaljer.sum")}{" "}
                        {jaktdag.Individer?.filter(
                          (skuttDyr) => skuttDyr.Terreng === Terreng.Innmark.toString(),
                        ).length || 0}
                      </div>
                    </div>
                  }
                >
                  {jaktdag.Individer?.filter(
                    (skuttDyr) => skuttDyr.Terreng === Terreng.Innmark.toString(),
                  ).map((skuttDyr) => (
                    <SkuttDyrCard
                      key={skuttDyr.Id}
                      skuttDyr={skuttDyr}
                      jegerNr={appData.jegeropplysninger.data?.Jegernummer}
                    />
                  ))}
                </AccordionDefault>
              </>
            )}

            {/* Utmark */}
            {((jaktdag.Art === ArtType.Hjort && jaktdag.Utmark) ||
              jaktdag.Art !== ArtType.Hjort) && (
              <>
                <AccordionDefault
                  headerContent={
                    <div className="flex justify-between">
                      <div>
                        {t("pages.registrerArt.sett")}{" "}
                        {jaktdag.Utmark ? t("pages.fellingsinfo.utmark").toLowerCase() : ""}
                        {sumSettOgSkutt.errors.utmark && (
                          <Warning className="text-md-error-red ml-2" />
                        )}
                      </div>
                      <div className="text-neutral-500 text-base font-normal">
                        {t("pages.oversikt.jaktdagDetaljer.sum")} {sumSettOgSkutt.sumSett}
                      </div>
                    </div>
                  }
                >
                  <CounterWithLabel
                    label={t(
                      `${tCounterLabels}.${
                        jaktdag.Art === ArtType.Elg ? "SettOkseEllerBukk" : "SettBukk"
                      }`,
                    )}
                    count={jaktdag.SettOkseEllerBukk}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettSpissbukk`)}
                    count={jaktdag.SettSpissbukk}
                  />
                  <CounterWithLabel
                    label={t(
                      `${tCounterLabels}.${
                        jaktdag.Art === ArtType.Elg ? "SettKyrUtenKalvEllerKolle" : "SettKolle"
                      }`,
                    )}
                    count={jaktdag.SettKyrUtenKalvEllerKolle}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettKyrMed1Kalv`)}
                    count={jaktdag.SettKyrMed1Kalv}
                  />
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettKyrMed2EllerFlereKalver`)}
                    count={jaktdag.SettKyrMed2EllerFlereKalver}
                  />
                  <CounterWithLabel
                    label={t(
                      `${tCounterLabels}.${
                        jaktdag.Art === ArtType.Elg ? "SettKalverAlene" : "SettKalv"
                      }`,
                    )}
                    count={jaktdag.SettKalverAlene}
                  />
                  {jaktdag.Art === ArtType.Elg && (
                    <CounterWithLabel
                      label={t(`${tCounterLabels}.sumKalver`)}
                      count={sumSettOgSkutt.settKalver}
                    />
                  )}
                  <CounterWithLabel
                    label={t(`${tCounterLabels}.SettUkjente`)}
                    count={jaktdag.SettUkjente}
                  />
                </AccordionDefault>

                <AccordionDefault
                  defaultOpen={false}
                  headerContent={
                    <div className="flex justify-between">
                      <div>
                        {t("pages.registrerArt.skutt")}{" "}
                        {jaktdag.Utmark ? t("pages.fellingsinfo.utmark").toLowerCase() : ""}
                      </div>
                      <div className="text-neutral-500 text-base font-normal">
                        {t("pages.oversikt.jaktdagDetaljer.sum")}{" "}
                        {jaktdag.Individer?.filter(
                          (skuttDyr) => skuttDyr.Terreng !== Terreng.Innmark.toString(),
                        ).length || 0}
                      </div>
                    </div>
                  }
                >
                  {jaktdag.Individer?.filter(
                    (skuttDyr) => skuttDyr.Terreng !== Terreng.Innmark.toString(),
                  ).map((skuttDyr) => (
                    <SkuttDyrCard
                      key={skuttDyr.Id}
                      skuttDyr={skuttDyr}
                      jegerNr={appData.jegeropplysninger.data?.Jegernummer}
                    />
                  ))}
                </AccordionDefault>
              </>
            )}
          </div>
          {canEdit.value && (
            <div className="p-4 text-right space-x-3 sticky bottom-0 bg-white">
              <ButtonDefault
                id="JaktdagDetaljerSlett"
                label={t("actions.delete")}
                variant={ButtonVariant.SecondaryWhite}
                onClick={handleDeleteClick}
              />
              <ButtonDefault
                id="JaktdagDetaljerEndre"
                label={t("actions.endre")}
                variant={ButtonVariant.Primary}
                onClick={() => navigate(`/registrering/jaktdag/${jaktdag.Id}`)}
              />
            </div>
          )}
          {deleteDialog.element}
        </>
      )}
    </>
  );
}
