import Dialog from "@mui/material/Dialog";
import React from "react";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import CalendarIcon from "../../assets/img/calendar-icon.svg?react";
import currentLocale from "../../helpers/locales";
import useCalendarHandlers from "../../hooks/useCalendarHandlers";
import { Stateful, useStateful } from "../../hooks/useStateful";
import ButtonDefault, { ButtonVariant } from "../Button/ButtonDefault";
import InputDefault, { InputDefaultProps } from "../Form/Input/InputDefault";

import { DialogActions, DialogContent } from "@mui/material";
import { format } from "date-fns";
import CardHeaderDefault from "../../pages/MainLayout/components/CardHeader/CardHeaderDefault";
import {
  calendarClasses,
  dateFormatNorsk,
  firstSelectableDate,
  numberOfMonths,
  today,
} from "./CalendarConstants";

export type DayPickerDefaultProps = {
  id: string;
  label: string;
  date: Stateful<Date>;
  dateFormat?: string;
  disabled?: boolean;
} & Omit<InputDefaultProps, "ref" | "crossOrigin" | "error">;

const CalendarPicker: React.ForwardRefRenderFunction<HTMLDivElement, DayPickerDefaultProps> = (
  { id, label, date, dateFormat = dateFormatNorsk, disabled = false },
  ref,
) => {
  const { t } = useTranslation();
  const showCalendar = useStateful<boolean>(false);
  const {
    handleOpen,
    handleInputChange,
    handleSelect,
    inputValid,
    inputValue,
    inputRef,
    errorMessage,
  } = useCalendarHandlers(date, showCalendar);
  return (
    <div ref={ref} id={id} className="bg-white" data-testid="calendarPicker">
      {!showCalendar?.value ? (
        <>
          <InputDefault
            id={id + "DateInput"}
            ref={inputRef}
            data-testid="fsCalendarInput"
            label={label}
            rightIcon={<CalendarIcon className="bg-md-greengray" />}
            rightIconOnClick={() => !disabled && handleOpen()}
            onFocus={() => !disabled && handleOpen()}
            onBlur={handleInputChange}
            defaultValue={format(date.value, dateFormatNorsk)}
            value={inputValue.value}
            validationErrorMessage={!inputValid.value ? errorMessage.value : undefined}
            inputClasses="w-40 rounded-t-lg pt-2"
            disabled={disabled}
          />
        </>
      ) : (
        <div
          className="flex flex-col h-screen justify-center align-middle"
          data-testid="fsCalendar"
        >
          <Dialog fullScreen fullWidth open={showCalendar.value} onClose={handleOpen}>
            <CardHeaderDefault
              pathProps={{
                pageTitle: t("components.dayPicker.title"),
                leftAction: t("actions.cancel"),
                onLeftActionClick: handleOpen,
              }}
            />

            <DialogContent className="bg-white mb-auto overflow-y-scroll border-0">
              <DayPicker
                id={id + "DayPicker"}
                data-testid="fsDayPicker"
                mode="single"
                ISOWeek={true}
                defaultMonth={firstSelectableDate}
                toDate={today}
                locale={currentLocale()}
                selected={date.value}
                onSelect={handleSelect}
                disableNavigation={true}
                numberOfMonths={numberOfMonths}
                showOutsideDays
                className="border-0 overflow-scroll flex justify-center"
                classNames={calendarClasses}
                reverseMonths
              />
            </DialogContent>
            <DialogActions className="sticky border border-t-md-sea-green-light">
              <div className="w-full flex justify-end">
                <ButtonDefault
                  id="calendarGoToToDay"
                  label={t("components.dayPicker.gaTilIdag")}
                  variant={ButtonVariant.SecondaryWhite}
                  onClick={() => {
                    date.set(today);
                    handleOpen();
                  }}
                  className="mr-2"
                />
                <ButtonDefault
                  id="calendarPickerElgLukk"
                  label={t("actions.close")}
                  onClick={handleOpen}
                />
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(CalendarPicker);
