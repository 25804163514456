import { AppConfig } from "../appConfig";
import { swlog } from "./logging";

export function registerServiceWorker(_appConfig: AppConfig) {
  if ("serviceWorker" in navigator) {
    swlog("updating service worker");
    navigator.serviceWorker
      .register(import.meta.env.DEV ? "/dev-sw.js?dev-sw" : "/sw.js", {
        type: import.meta.env.MODE === "production" ? "classic" : "module",
      })
      .then((_registration) => {
        swlog("updated service worker");
      })
      .catch((e) => {
        swlog("could not register service worker", e);
      });
    const controller = navigator.serviceWorker.controller;
    if (controller) {
      controller.addEventListener("controllerchange", () => {
        window.location.reload();
      });
      controller.postMessage("finished service worker setup in application");
    }
  } else {
    swlog("could not register service worker", Error("service worker not supported"));
  }
}
