import { UTCDate } from "@date-fns/utc";
import { format, parseISO } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import currentLocale from "./locales";

export const formatDmy = (date: string | null | undefined) =>
  format(date || "", "dd.MM.yyyy", { locale: currentLocale() });

export function ticksToDate(ticks: number): UTCDate {
  return new UTCDate(new Date("0001-01-01T00:00:00Z").getTime() + ticks / 1e4);
}

export function toStringWithFullMonth(date: string | null | undefined): string {
  return format(date || "", "d. MMM", { locale: currentLocale() });
}

export function formatISOWithoutTimestamp(
  date: Date | string | null | undefined,
): string | null | undefined {
  if (!date) {
    return date;
  }
  let _date = date;
  if (typeof date === "string") {
    _date = parseISO(date);
  }
  return format(_date, dateFormatISO);
}
