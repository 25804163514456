import { Add, CheckCircle, Info, NavigateNext, Sync, SyncProblem } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ArtType } from "~/src/helpers/types";
import { IndividResponse } from "../../api/types";
import { getKjonnOgAlderByArt } from "../../helpers/artHelper";
import { getFirstAndLastName } from "../../helpers/stringHelper";
import { toStringWithFullMonth } from "../../helpers/time";
import { getReadableEnumLowercase, isMissingOvervakData } from "../../pages/Oversikt/helpers";
import { MalemetodeSlaktevekt } from "../../pages/Registrering/types";

type SkuttDyrCardProps = {
  skuttDyr: IndividResponse;
  jegerNr?: number;
  syncStatus?: "pending" | "error";
};

export default function SkuttDyrCard({
  skuttDyr,
  jegerNr,
  syncStatus,
}: SkuttDyrCardProps): JSX.Element {
  const missingPropStyles = " bg-md-warning-light";
  let variationStyles = "";
  const isRegByOther = jegerNr && skuttDyr.Jegernummer !== jegerNr;
  const { t } = useTranslation();
  const ikkeVeid = `: ${t("pages.registrerArt.omSkuttDyr.ikkeVeid")}`;

  if (skuttDyr.Godkjent) variationStyles += " bg-md-secondary";
  else if (isRegByOther) variationStyles += " bg-md-creme-light";
  else variationStyles += " bg-md-greengray";

  const [firstName, lastName] = getFirstAndLastName(skuttDyr.RegistrertAv);
  const storageKey = `formState-${skuttDyr.Id}`;
  const hasDraft = !!localStorage.getItem(storageKey);

  const detailsItem = (label: string, value?: string | null) => {
    return (
      <li
        key={label}
        className={
          "bg-md-lysegronn inline-block p-1 m-1 rounded " + (!value ? missingPropStyles : "")
        }
      >
        {label}: {value || "-"}
      </li>
    );
  };

  return (
    <div
      className={"relative rounded mb-2 p-3 text-md-gray font-normal text-base " + variationStyles}
    >
      <NavLink to={`/oversikt/skuttdyrdetaljer/${skuttDyr.Art}/${skuttDyr.Id}`}>
        <div className="flex gap-5 font-semibold text-md-primary active:bg-md-secondary">
          <div>{skuttDyr.Dato && toStringWithFullMonth(skuttDyr.Dato)}</div>
          <div>
            {skuttDyr.Art} {getKjonnOgAlderByArt(skuttDyr)}
          </div>
          {skuttDyr.Godkjent && (
            <CheckCircle
              className="text-md-primary ml-[-10px]"
              titleAccess={t("pages.oversikt.dyretErGodkjent")}
            />
          )}

          {skuttDyr.UtenforJakttid && (
            <Info
              className="text-md-warning-dark ml-[-10px]"
              titleAccess={t("pages.oversikt.skuttDyrUtenforJakttid")}
            />
          )}

          {isMissingOvervakData(skuttDyr) && (
            <Info
              className="text-md-warning-dark ml-[-10px]"
              titleAccess={t("pages.oversikt.skuttIOvervakomrade")}
            />
          )}
          <div className="ml-auto">
            {hasDraft && !skuttDyr.Godkjent && (
              <EditNoteIcon
                className="text-md-sea-green-dark ml-[-10px]"
                titleAccess={t("pages.oversikt.hasIndividDraftTitle")}
              />
            )}
            <NavigateNext />
          </div>
        </div>

        <ul className="text-sm space-y-2">
          {skuttDyr.Art === ArtType.Hjort &&
            detailsItem(t("components.skuttDyrCard.terreng"), skuttDyr.Terreng)}
          {detailsItem(
            t("components.skuttDyrCard.weight"),
            skuttDyr.SlaktevektKg
              ? `${skuttDyr.SlaktevektKg} kg. ${getReadableEnumLowercase(
                  skuttDyr.MålemetodeSlaktevekt,
                )}`
              : skuttDyr.MålemetodeSlaktevekt === MalemetodeSlaktevekt.IkkeVeid
                ? ikkeVeid
                : undefined,
          )}
          {detailsItem(t("components.skuttDyrCard.tagNo"), skuttDyr.Merkelapp)}
          {detailsItem(
            t("components.skuttDyrCard.registrertAv"),
            firstName && `${firstName.substring(0, 1)}. ${lastName}`,
          )}
        </ul>
      </NavLink>
      {!skuttDyr.Godkjent && (
        <NavLink to={`/registrering/fellingsinfo/${skuttDyr.Id}`}>
          <div className="py-4 -mb-1 pl-2 active:bg-md-secondary rounded">
            <Add className="text-md-primary mt-[-3px]" /> {t("components.skuttDyrCard.addInfo")}
          </div>
        </NavLink>
      )}
      {syncStatus && (
        <div className="absolute right-0 bottom-0 m-3">
          {syncStatus === "pending" && (
            <Sync
              className="text-md-primary text-3xl"
              titleAccess="Individ har enda ikke blitt overført til hjorteviltregisteret"
            />
          )}
          {syncStatus === "error" && (
            <SyncProblem className="text-md-error-red text-3xl" titleAccess="Feil ved overføring" />
          )}
        </div>
      )}
    </div>
  );
}
