import { t } from "i18next";
import { NOT_AUTHENTICATED_STATUS_CODES } from "~/src/api/jaktdataApi";
import { ApiError, Jaktdag } from "~/src/api/types";
import { jaktdataApi } from "~/src/appGlobals";
import { isDeletedOnServer, syncStatusForError } from "~/src/contexts/AppDataContext/syncHelpers";
import { AppStorage, StorageItem } from "../StorageContext/StorageProvider";
import { UIContextData } from "../UIContext/UIProvider";

export const syncJaktdager = async (storage: AppStorage, ui: UIContextData) => {
  const pendingJaktdager = storage.getAll<Jaktdag>("Jaktdag");
  if (pendingJaktdager.length === 0) return;

  const toCreate = pendingJaktdager.filter((jaktdag) => jaktdag.operation === "create");
  const toUpdate = pendingJaktdager.filter((jaktdag) => jaktdag.operation === "update");
  const toDelete = pendingJaktdager.filter((jaktdag) => jaktdag.operation === "delete");

  let jaktdagerSynced = 0;
  let jaktdagerNotSynced = 0;
  let createRequests: Promise<void>[] = [];
  let updateRequests: Promise<void>[] = [];
  let deleteRequests: Promise<void>[] = [];

  const syncConfirm = (jaktdag: StorageItem<Jaktdag>) => {
    jaktdagerSynced += 1;
    storage.delete({ id: jaktdag.data.Id }, "Jaktdag");
  };
  const syncReject = (jaktdag: StorageItem<Jaktdag>, e: ApiError) => {
    jaktdagerNotSynced += 1;
    if (!NOT_AUTHENTICATED_STATUS_CODES.includes(e.statusCode)) {
      storage.add({
        ...jaktdag,
        syncStatus: syncStatusForError(e),
        errors: [e.message],
      });
    }
  };

  for (const jaktdag of toCreate) {
    createRequests.push(
      jaktdataApi
        .postJaktdagWithoutIndivid(jaktdag.data)
        .then(() => syncConfirm(jaktdag))
        .catch((e: ApiError) => syncReject(jaktdag, e)),
    );
  }

  for (const jaktdag of toUpdate) {
    updateRequests.push(
      jaktdataApi
        .putJaktdagWithoutIndivid(jaktdag.data)
        .then(() => syncConfirm(jaktdag))
        .catch((e: ApiError) => {
          if (isDeletedOnServer(e)) {
            syncConfirm(jaktdag);
          } else {
            syncReject(jaktdag, e);
          }
        }),
    );
  }

  for (const jaktdag of toDelete) {
    deleteRequests.push(
      jaktdataApi
        .deleteJaktdag(jaktdag.data.Id)
        .then(() => syncConfirm(jaktdag))
        .catch((e: ApiError) => {
          if (isDeletedOnServer(e)) {
            syncConfirm(jaktdag);
          } else {
            syncReject(jaktdag, e);
          }
        }),
    );
  }

  return Promise.all(createRequests)
    .then(() => Promise.all(updateRequests))
    .then(() => Promise.all(deleteRequests))
    .then(() => {
      if (jaktdagerSynced > 0) {
        ui.showSnackbar({
          message: t("sync.success", {
            count: jaktdagerSynced,
            type: t("sync.type.jaktdag"),
          }),
        });
      }
      if (jaktdagerNotSynced > 0) {
        ui.showSnackbar({
          message: t("sync.failure", {
            count: jaktdagerNotSynced,
            type: t("sync.type.jaktdag"),
            details: t("sync.syncErrorDetailsWhereJaktdag"),
          }),
        });
      }

      return {
        jaktdagerSynced,
        jaktdagerNotSynced,
        totalItemsHandled: jaktdagerSynced + jaktdagerNotSynced,
      };
    });
};

export type SyncJaktdagerResult = {
  jaktdagerSynced: number;
  jaktdagerNotSynced: number;
  totalItemsHandled: number;
};

export const SyncJaktdagerResultDefaults: SyncJaktdagerResult = {
  jaktdagerSynced: 0,
  jaktdagerNotSynced: 0,
  totalItemsHandled: 0,
};
