import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const externalLinkIcon = <FontAwesomeIcon icon={faExternalLink} />;

export default function ExternalLink({ url, text, icon }: ExternalLinkProps): JSX.Element {
  let Icon = null;
  if (icon)
    Icon = React.cloneElement(icon, {
      htmlColor: "gray",
      style: { marginRight: 10 },
    });

  return (
    <div className="flex">
      {Icon}
      <a href={url} className="text-md-primary" target="_blank">
        <span className="underline mr-1">{text}</span> {externalLinkIcon}
      </a>
    </div>
  );
}

type ExternalLinkProps = {
  url: string;
  text: string;
  icon?: JSX.Element;
};
