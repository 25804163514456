import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash/orderBy";
import { ChangeEvent, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StorageContext, StorageItem } from "~/src/contexts/StorageContext/StorageProvider";
import {
  Individ,
  IndividResponse,
  Jaktdag,
  JaktdagResponse,
  JaktomradeResponse,
} from "../../api/types";
import { ComboboxOption } from "../../components/Form/Combobox/Combobox";
import JaktdagCard from "../../components/JaktdagCard/JaktdagCard";
import JaktomradePicker from "../../components/JaktomradePicker/JaktomradePicker";
import SkuttDyrCard from "../../components/SkuttDyrCard/SkuttDyrCard";
import TabPanel from "../../components/Tab/TabPanel";
import TabsDefault from "../../components/Tab/TabsDefault";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import { ArtType } from "../../helpers/types";
import { useStateful } from "../../hooks/useStateful";
import { getJaktdagerQuery, getSkutteDyrQuery } from "../../react-query/queries";
import TomtResultat from "./Components/TomtResultat";

export default function Oversikt(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const appData = useContext(AppDataContext);
  const storage = useContext(StorageContext);
  const storageKey = { name: "oversikt-jaktomrade" };
  const missingAreaSelectionText = t("pages.oversikt.missingAreaSelection");
  const noResultText = t("pages.oversikt.noRegistrations");
  const tabValue = useStateful(0);
  const jaktomrade = useStateful<ComboboxOption<JaktomradeResponse> | null>(null);

  const artFilter = useStateful<ArtType[]>(Object.values(ArtType));
  const valgtOmradekode =
    location.state && "valgtOmradekode" in location.state && location.state.valgtOmradekode;
  const jaktdager = useQuery({
    queryKey: [
      "jaktdager",
      {
        jaktomradeId: jaktomrade.value?.id || "",
        sesong: new Date().getFullYear(),
        artFilter: artFilter.value,
      },
    ],
    queryFn: (context) =>
      getJaktdagerQuery(context).then((jaktdager) => {
        var storedItems = storage
          .getAll<JaktdagResponse>("Jaktdag")
          .filter((jaktdag) => jaktdag.data.Jaktfelt === jaktomrade.value?.id);

        const createdItems = storedItems.filter((item) => item.operation === "create");
        const updatedItems = storedItems.filter((item) => item.operation === "update");
        const deletedItems = storage
          .getAll<JaktdagResponse>("Jaktdag")
          .filter((item) => item.operation === "delete");

        updatedItems.forEach((updatedItem) => {
          const existingItem = jaktdager.find((j) => j.Id === updatedItem.data.Id);
          if (!existingItem) return;
          updatedItem.data.Individer = existingItem.Individer;
        });

        const queryItems = jaktdager.map((jaktdag): StorageItem<JaktdagResponse> => {
          return {
            objectKey: { id: jaktdag.Id },
            isPending: false,
            data: jaktdag,
          };
        });

        const displayedItems = createdItems
          .concat(updatedItems)
          .concat(
            // exclude items that are in updatedItems and deletedItems
            // deleted items should not be shown
            queryItems.filter(
              (item) =>
                deletedItems.concat(updatedItems).find((u) => u.data.Id === item.data.Id) ===
                undefined,
            ),
          )
          .concat(deletedItems.filter((deletedItem) => (deletedItem.errors?.length || 0) > 0));

        // set individer that might be in storage for each jaktdag
        displayedItems.forEach((item) => {
          storage.setIndividerForJaktdag(item.data as Jaktdag);
        });

        return orderBy(displayedItems, ["data.Dato"], ["desc"]);
      }),
    enabled: !!jaktomrade.value?.id,
    select: (response) => jaktdagFilter(response),
    staleTime: 0,
  });

  const jaktdagFilter = (
    response: StorageItem<JaktdagResponse>[],
  ): StorageItem<JaktdagResponse>[] => {
    return response.filter(
      (r) =>
        !showArtFilter ||
        artFilter.value.length == 0 ||
        (showArtFilter && r.data.Art && artFilter.value.includes(r.data.Art as ArtType)),
    );
  };

  const skutteDyr = useQuery({
    queryKey: [
      "skuttedyr",
      {
        jaktomradeId: jaktomrade.value?.id || "",
        sesong: new Date().getFullYear(),
        artFilter: artFilter.value,
      },
    ],
    queryFn: (context) =>
      getSkutteDyrQuery(context).then((skutteDyr) => {
        const storedIndivider = storage
          .getAll<Individ>("Individ")
          .filter((individ) =>
            [individ.data.Jaktfelt, individ.data.Vald, individ.data.Villreinvald].includes(
              jaktomrade.value?.id,
            ),
          );
        const deletedJaktdagerIds = storage
          .getAll<Jaktdag>("Jaktdag")
          .filter(
            (jaktdag) => jaktdag.operation === "delete" && (jaktdag.errors?.length || 0) === 0,
          )
          .map((jaktdag) => jaktdag.data.Id);

        const queryItems = skutteDyr.map((skuttDyr): StorageItem<IndividResponse> => {
          return {
            objectKey: { id: skuttDyr.Id },
            isPending: false,
            data: skuttDyr,
          };
        });

        const createdItems = storedIndivider.filter((item) => item.operation === "create");
        const updatedItems = storedIndivider.filter((item) => item.operation === "update");
        const deletedItems = storedIndivider
          .filter((item) => item.operation === "delete")
          // individer connected to deleted jaktdager should not be shown
          .concat(
            (queryItems as StorageItem<Individ>[]).filter((item) =>
              deletedJaktdagerIds.includes(item.data.JaktdagId || ""),
            ),
          );

        const displayedItems = createdItems.concat(updatedItems).concat(
          // exclude items that are in updatedItems and deletedItems
          // deleted items should not be shown
          (queryItems as StorageItem<Individ>[])
            .filter(
              (item) =>
                updatedItems.concat(deletedItems).find((u) => u.data.Id === item.data.Id) ===
                undefined,
            )
            .concat(deletedItems.filter((deletedItem) => (deletedItem.errors?.length || 0) > 0)),
        );

        return orderBy(displayedItems, ["data.Dato"], ["desc"]);
      }),
    enabled: !!jaktomrade.value?.id,
    select: (response) => skutteDyrFilter(response),
    staleTime: 0,
  });
  const skutteDyrFilter = (
    response: StorageItem<IndividResponse>[],
  ): StorageItem<IndividResponse>[] => {
    return response.filter(
      (r) =>
        !showArtFilter ||
        artFilter.value.length == 0 ||
        (showArtFilter && r.data.Art && artFilter.value.includes(r.data.Art as ArtType)),
    );
  };

  useEffect(() => {
    if (appData.didTrySyncWithItems.value) {
      jaktdager.refetch();
      skutteDyr.refetch();
    }
  }, [appData.didTrySyncWithItems.value]);

  useEffect(() => {
    const storedJaktomrade = storage.get<ComboboxOption<JaktomradeResponse>>(storageKey);
    if (storedJaktomrade && !valgtOmradekode) {
      jaktomrade.set(storedJaktomrade.data);
    }
  }, []);

  const handleSelectJaktomrade = (selected: ComboboxOption<JaktomradeResponse> | null) => {
    if (!selected || !selected?.id) return;
    artFilter.set(Object.values(ArtType));
    jaktomrade.set(selected);
    storage.add({
      objectKey: storageKey,
      data: selected,
    });
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const filterValue = event.target.value;
    if (checked) artFilter.set([...artFilter.value, filterValue] as ArtType[]);
    else {
      artFilter.set(artFilter.value.filter((f) => f !== filterValue));
    }
  };

  const showMissingSelection = !jaktomrade.value;
  const hasJaktdager = jaktdager.data !== undefined && jaktdager.data.length > 0;
  const hasSkutteDyr = skutteDyr.data !== undefined && skutteDyr.data.length > 0;
  const showJaktdagTab = jaktomrade.value?.data?.JaktPåElg || jaktomrade.value?.data?.JaktPåHjort;
  const showArtFilter = jaktomrade.value?.data?.JaktPåElg && jaktomrade.value?.data?.JaktPåHjort;
  const tomtResultatClassName = "mt-5 whitespace-pre-line";

  useEffect(() => {
    if (!hasJaktdag(jaktomrade.value)) {
      tabValue.set(1);
    } else {
      const requestedTab = location.state?.activeTab;
      if (requestedTab !== undefined && requestedTab !== tabValue.value) {
        tabValue.set(location.state?.activeTab);
      }
    }
  }, [jaktomrade.value, location.state?.activeTab]);

  const hasJaktdag = (jaktomrade: ComboboxOption<JaktomradeResponse> | null) => {
    return jaktomrade && (jaktomrade.data?.JaktPåElg || jaktomrade.data?.JaktPåHjort);
  };

  const showLastSynced =
    appData.lastSynced.value !== undefined &&
    (appData.isOnline.value === false ||
      (appData.isOnline.value === true && appData.isSynced.value === false));

  return (
    <div>
      {showLastSynced && (
        <div className="flex flex-row justify-end items-end pr-4 w-full">
          {`${t("sync.sistSynkronisert")} ${appData.lastSynced.value}`}
        </div>
      )}
      <div className="p-4 pt-8">
        <JaktomradePicker
          onChange={handleSelectJaktomrade}
          defaultOmradekode={jaktomrade.value?.id || valgtOmradekode}
        />

        {showMissingSelection ? (
          <TomtResultat>
            <div className={tomtResultatClassName}>{missingAreaSelectionText}</div>
          </TomtResultat>
        ) : (
          <>
            {showArtFilter && (
              <div className="flex gap-10 items-center mb-3">
                Vis
                <FormGroup row className="flex gap-5">
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked onChange={handleFilter} value={ArtType.Elg} />
                    }
                    label={t("fauna.art.elg.artsnavn")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked onChange={handleFilter} value={ArtType.Hjort} />
                    }
                    label={t("fauna.art.hjort.artsnavn")}
                  />
                </FormGroup>
              </div>
            )}
            <div key={tabValue.value}>
              <TabsDefault
                value={tabValue.value}
                onChange={(_e, newValue) => tabValue.set(newValue)}
              >
                <Tab
                  label={t("pages.oversikt.jaktdager")}
                  className={!showJaktdagTab ? "hidden" : ""}
                />
                <Tab label={t("pages.oversikt.skutteDyr")} />
              </TabsDefault>
              <TabPanel
                value={tabValue.value}
                index={0}
                className={!showJaktdagTab ? "hidden" : ""}
              >
                {(hasJaktdager &&
                  jaktdager.data.map((jaktdag: StorageItem<JaktdagResponse>, index) => (
                    <JaktdagCard
                      key={index}
                      data={jaktdag.data}
                      jegerNr={appData.jegeropplysninger.data?.Jegernummer}
                      syncStatus={jaktdag.syncStatus}
                      storage={storage}
                    />
                  ))) || (
                  <TomtResultat>
                    <div className={tomtResultatClassName}>{noResultText}</div>
                  </TomtResultat>
                )}
              </TabPanel>
              <TabPanel value={tabValue.value} index={1}>
                {(hasSkutteDyr &&
                  skutteDyr.data.map((skuttDyr: StorageItem<IndividResponse>, index) => (
                    <SkuttDyrCard
                      key={index}
                      skuttDyr={skuttDyr.data}
                      syncStatus={skuttDyr.syncStatus}
                      jegerNr={appData.jegeropplysninger.data?.Jegernummer}
                    />
                  ))) || (
                  <TomtResultat>
                    <div className={tomtResultatClassName}>{noResultText}</div>
                  </TomtResultat>
                )}
              </TabPanel>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
