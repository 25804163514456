/**
 * For forms using useForm.
 * Sets API errors in the form using the provided response and setError function.
 *
 * @param response - The response object containing the API errors.
 * @param setError - The function to set the error(s) in the form.
 * @returns void
 * @template T - The type of the form values.
 */
// biome-ignore lint/suspicious/noExplicitAny: Can't set a type for response in this case since we're passing a variable that is already type "any"
export function setApiErrorsUseForm<T>(response: any, setError: any) {
  const errors = response.axiosError.response.data.errors;
  for (const error in errors) {
    setError(error as keyof T, { type: "server", message: errors[error][0] });
  }
}

/**
 * For forms not using any form library.
 * Sets API errors in the form using the provided response and setError function.
 *
 * @param error - The response object containing the API errors.
 * @param setErrors - The function to set the error(s) in the form.
 * @returns void
 * @template T - The type of the form values.
 */
// biome-ignore lint/suspicious/noExplicitAny: Can't set a type for response in this case since we're passing a variable that is already type "any"
export function setApiErrors<T>(error: any, setErrors: any) {
  const errors = error.axiosError?.response?.data?.errors;
  const fieldErrors: Partial<Record<keyof T, string>> = {};
  for (const error in errors) {
    fieldErrors[error as keyof T] = errors[error][0];
  }
  setErrors(fieldErrors);
}
